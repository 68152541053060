<template>
  <main id="cart">
    <bar-breadcrumbs :data="breadcrumbs" />

    <client-only>
      <section class="bar__categories">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full px-4">
              <header-h3
                :content="$t('components.cart.data')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="0"
              />
              <div v-if="cartQty === 0">
                <div
                  v-html="$t('components.cart.empty')"
                  class="my-4 bg-orange text-white px-4 py-2"
                />
              </div>
              <div v-else>
                <div
                  v-if="message"
                  v-html="errorText"
                  class="my-4 bg-orange text-white px-4 py-2">
                </div>
                <form
                  id="cart-form"
                  ref="cartForm"
                  @submit.prevent="isPaymentEnabled || is_shipping_form_open ? () => {} : submitOrder()"
                  @keydown.enter.prevent.stop="isPaymentEnabled || is_shipping_form_open ? () => {} : submitOrder()"
                  @keypress.enter.prevent.stop="isPaymentEnabled || is_shipping_form_open ? () => {} : submitOrder()">
                  <p
                    v-if="!loggedIn"
                    v-html="
                      `${$t('components.cart.account')} <a href='${localePath(
                        'account'
                      )}'>${$t('components.account.login')}</a>`
                    "
                    class="mt-6 text-center"
                  />
                  <div class="flex flex-wrap mt-6 -mx-4">
                    <div class="w-full tablet:w-2/3 px-4" >
                      <div id="billingAddress">
                        <header-h5
                          :content="$t('components.cart.headers.personal_data')"
                          casing="uppercase"
                          mt="0"
                          mb="4"
                        />
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="
                            `${$t('components.account.form.company_name')}`
                          "
                            for-id="form_field_company_name"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="company_name"
                            :value="company_name"
                            :required="true"
                            :placeholder="`${$t('components.account.form.company_name')} *`"
                            :is-disabled="loggedIn"
                            name="company_name"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="
                            `${$t('components.account.form.first_name')} *`
                          "
                            for-id="form_field_first_name"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="first_name"
                            :value="first_name"
                            :required="true"
                            :placeholder="`${$t('components.account.form.first_name')} *`"
                            :is-disabled="loggedIn"
                            name="first_name"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="
                            `${$t('components.account.form.last_name')} *`
                          "
                            for-id="form_field_last_name"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="last_name"
                            :value="last_name"
                            :required="true"
                            :placeholder="`${$t('components.account.form.last_name')} *`"
                            :is-disabled="loggedIn"
                            name="last_name"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="`${$t('components.account.form.street')} *`"
                            for-id="form_field_street"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="street"
                            :value="street"
                            :required="true"
                            :placeholder="`${$t('components.account.form.street')} *`"
                            :is-disabled="loggedIn"
                            name="street"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="
                            `${$t('components.account.form.house_number')} *`
                          "
                            for-id="form_field_house_number"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="house_number"
                            :value="house_number"
                            :required="true"
                            :placeholder="`${$t('components.account.form.house_number')} *`"
                            :is-disabled="loggedIn"
                            name="house_number"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="
                            `${$t('components.account.form.postal_code')} *`
                          "
                            for-id="form_field_postal_code"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="postal_code"
                            :value="postal_code"
                            :required="true"
                            :placeholder="`${$t('components.account.form.postal_code')} *`"
                            :is-disabled="loggedIn"
                            name="postal_code"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="`${$t('components.account.form.city')} *`"
                            for-id="form_field_city"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="city"
                            :value="city"
                            :required="true"
                            :placeholder="`${$t('components.account.form.city')} *`"
                            :is-disabled="loggedIn"
                            name="city"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="`${$t('components.account.form.country')} *`"
                            for-id="form_field_country"
                            extra-class="block mb-2 hidden"
                          />
                          <div class="form_item">
                            <select
                              v-model="country"
                              id="country2"
                              name="country2"
                              class="form-select"
                              :disabled="loggedIn"
                              :placeholder="$t('components.account.form.country_select')"
                              required>
                              <option value="" style="color: #a0aec0;">{{ $t('components.account.form.country_select') }}</option>
                              <option
                                v-for="(item, itemIndex) in countriesList"
                                :key="'country-2-option-' + itemIndex"
                                :selected="item.value == country"
                                :value="item.value">
                                {{ item.label }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="`${$t('components.account.form.phone')} *`"
                            for-id="form_field_phone"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="phone"
                            :value="phone"
                            :required="!loggedIn"
                            :placeholder="`${$t('components.account.form.phone')} *`"
                            :is-disabled="loggedIn"
                            type="tel"
                            name="phone"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="`${$t('components.account.form.email')} *`"
                            for-id="form_field_email"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="email"
                            :value="email"
                            :required="true"
                            :placeholder="`${$t('components.account.form.email')} *`"
                            :is-disabled="loggedIn"
                            type="email"
                            name="email"
                          />
                        </div>
                      </div>
                      <div id="shippingAddress">
                        <div class="shipping-address-header ">
                          <header-h5
                            :content="$t('components.cart.headers.delivery_data')"
                            casing="uppercase"
                            mt="0"
                            mb="4"/>
                          <div class="my-auto">
                            <form-checkbox
                              id="billing_as_shipping"
                              name="billing_as_shipping"
                              :label="$t('components.cart.billing_as_shipping')"
                              value="checked"
                              :is-default="billing_as_shipping"
                              @change="shippingDetailsChanged"
                              class="mb-4" />
                          </div>
                        </div>
                        <template v-if="!billing_as_shipping">
                          <div
                            v-if="loggedIn"
                            class="w-full mb-4">
                            <select
                              v-model="order_shipping_address_id"
                              id="shipping-address"
                              name="shippingAddress"
                              class="form-select"
                              :disabled="!loggedIn || is_payment_processing || !is_order_shipping_addresses_loaded"
                              :placeholder="`${$t('components.cart.choose_address')} *`"
                              required>
                              <option value="">{{ $t('components.cart.choose_address') }}*</option>
                              <option
                                v-for="(item, itemIndex) in shippingAddressesList"
                                :key="'shipping-option-' + itemIndex"
                                :selected="item.value == order_shipping_address_id"
                                :value="item.value">
                                {{ item.label }}
                              </option>
                            </select>
                            <div
                              v-if="is_order_shipping_addresses_error"
                              class="my-4 bg-orange text-white px-4 py-2">
                              {{ $t('components.cart.fetch_shipping_addresses_error') }}
                            </div>
                          </div>
                          <div
                            v-else
                            id="shippingAddressData">
                            <div class="form-group w-full mb-4">
                              <form-label
                                :label="
                              `${$t('components.account.form.company_name')}`
                            "
                                for-id="form_field_company_name"
                                extra-class="block mb-2 hidden"
                              />
                              <form-input
                                v-model="shipping.company_name"
                                :value="shipping.company_name"
                                :required="true"
                                :placeholder="
                              `${$t('components.account.form.company_name')} *`
                            "
                                name="shipping_company_name"
                              />
                            </div>
                            <div class="form-group w-full mb-4">
                              <form-label
                                :label="
                              `${$t('components.account.form.first_name')} *`
                            "
                                for-id="form_field_first_name"
                                extra-class="block mb-2 hidden"
                              />
                              <form-input
                                v-model="shipping.first_name"
                                :value="shipping.first_name"
                                :required="true"
                                :placeholder="
                              `${$t('components.account.form.first_name')} *`
                            "
                                name="shipping_first_name"
                              />
                            </div>
                            <div class="form-group w-full mb-4">
                              <form-label
                                :label="
                              `${$t('components.account.form.last_name')} *`
                            "
                                for-id="form_field_last_name"
                                extra-class="block mb-2 hidden"
                              />
                              <form-input
                                v-model="shipping.last_name"
                                :value="shipping.last_name"
                                :required="true"
                                :placeholder="
                              `${$t('components.account.form.last_name')} *`
                            "
                                name="shipping.last_name"
                              />
                            </div>
                            <div class="form-group w-full mb-4">
                              <form-label
                                :label="`${$t('components.account.form.street')} *`"
                                for-id="form_field_street"
                                extra-class="block mb-2 hidden"
                              />
                              <form-input
                                v-model="shipping.street"
                                :value="shipping.street"
                                :required="true"
                                :placeholder="
                              `${$t('components.account.form.street')} *`
                            "
                                name="shipping_street"
                              />
                            </div>
                            <div class="form-group w-full mb-4">
                              <form-label
                                :label="
                              `${$t('components.account.form.house_number')} *`
                            "
                                for-id="form_field_house_number"
                                extra-class="block mb-2 hidden"
                              />
                              <form-input
                                v-model="shipping.house_number"
                                :value="shipping.house_number"
                                :required="true"
                                :placeholder="
                              `${$t('components.account.form.house_number')} *`
                            "
                                name="shipping_house_number"
                              />
                            </div>
                            <div class="form-group w-full mb-4">
                              <form-label
                                :label="
                              `${$t('components.account.form.postal_code')} *`
                            "
                                for-id="form_field_postal_code"
                                extra-class="block mb-2 hidden"
                              />
                              <form-input
                                v-model="shipping.postal_code"
                                :value="shipping.postal_code"
                                :required="true"
                                :placeholder="
                              `${$t('components.account.form.postal_code')} *`
                            "
                                name="shipping_postal_code"
                              />
                            </div>
                            <div class="form-group w-full mb-4">
                              <form-label
                                :label="`${$t('components.account.form.city')} *`"
                                for-id="form_field_city"
                                extra-class="block mb-2 hidden"
                              />
                              <form-input
                                v-model="shipping.city"
                                :value="shipping.city"
                                :required="true"
                                :placeholder="
                              `${$t('components.account.form.city')} *`
                            "
                                name="shipping_city"
                              />
                            </div>
                            <div class="form-group w-full mb-4">
                              <form-label
                                :label="`${$t('components.account.form.country')} *`"
                                for-id="form_field_country"
                                extra-class="block mb-2 hidden"
                              />
                              <div class="form_item">
                                <select
                                  v-model="shipping.country"
                                  id="country2"
                                  name="country2"
                                  class="form-select"
                                  :placeholder="$t('components.account.form.country_select')"
                                  required>
                                  <option value="" style="color: #a0aec0;">{{ $t('components.account.form.country_select') }}</option>
                                  <option
                                    v-for="(item, itemIndex) in countriesList"
                                    :key="'country-2-option-' + itemIndex"
                                    :selected="item.value == country"
                                    :value="item.value">
                                    {{ item.label }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="w-full tablet:w-1/3 px-4">
                      <header-h5
                        :content="$t('components.cart.headers.order_data')"
                        casing="uppercase"
                        mt="0"
                        mb="4"
                      />
                      <table-main
                        :table="{
                          thead: [
                            [
                              $t('components.cart.headers.product_name'),
                              $t('components.cart.headers.price')
                            ]
                          ],
                          tbody: cartItems,
                          tfoot: [
                            [
                              $t('components.cart.headers.sub_total'),
                              getPriceFormatted(sub_total)
                            ]
                          ]
                        }"
                        extra-class="table--sm"
                      />
                      <div class="mt-4">
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="
                              `${$t('components.account.form.order_ref')}`
                            "
                            for-id="form_field_order_ref"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="order_ref"
                            :required="false"
                            :placeholder="
                              `${$t('components.account.form.order_ref')}`
                            "
                            name="order_ref"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="`${$t('components.account.form.remarks')}`"
                            for-id="form_field_remarks"
                            extra-class="block mb-2 hidden"
                          />
                          <form-textarea
                            v-model="remarks"
                            :required="false"
                            :placeholder="
                              `${$t('components.account.form.remarks')}`
                            "
                            name="remarks"
                          />
                        </div>
                      </div>
                      <div class="mt-4">
                        <button
                          @click="setInstantPayment(false)"
                          type="submit"
                          :disabled="is_payment_processing"
                          class="button button--primary block mt-4">
                          <span>{{ $t('form.send') }}</span>
                        </button>

                        <button
                          v-if="canInstantPayment()"
                          @click="setInstantPayment(true)"
                          type="submit"
                          :disabled="is_payment_processing"
                          class="button button--primary block mt-4">
                          <span>{{ $t('components.cart.instant_pay') }} *</span>
                        </button>
                        <small class="mt-2 inline-block" v-if="canInstantPayment()"><strong>{{ $t('components.cart.instant_payment_info') }}</strong><br />- {{ $t('components.cart.instant_payment_shipping') }} {{ getPriceFormatted(10) }} {{ $t('components.cart.net') }}<br />- {{ $t('components.cart.instant_payment_vat') }}</small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </client-only>

    <shipping-data-modal
      :is-open="is_shipping_form_open"
      @closeModal="closeShippingModal" />
  </main>
</template>

<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs';
import portal from '../../service/portal';
import CommonUtils from '/helpers/CommonUtils';
import Countries from '../../helpers/Countries';
import ShippingDataModal from '/components/checkout/shippingDataModal.vue';

export default {
  name: 'Cart',
  components: { BarBreadcrumbs, ShippingDataModal
   },
  head() {
    const title = 'Cart - Air Conditioning Parts - AP Air LTD.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air LTD.' }
      ]
    }
  },
  filters: {
    formatPrice(val, isTax = false) {
      if (val === 0 && !isTax) {
        return 'P.O.A.'
      } else {
        return val
          .toFixed(2)
          .toString()
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    }
  },
  computed: {
    isPaymentEnabled () {
      // TODO: only for shipping to UK
      return false;
    },
    countriesList() {
      let countries = Countries.map(country => {
        if (country.value === this.country) {
          return { ...country, default: true };
        }
        return { ...country, default: false };
      });
      return countries;
    },
    shippingAddressesList () {
      let addressesList = [{
        label: this.$t('components.cart.add_address'),
        value: '0'
      }];

      let addressesWithValue = this.shipping_addresses.map(item => {
        item.label = `${item.street} ${item.number}, ${item.postal_code} ${item.city}, ${this.getCountryName(item.country)}`;
        item.value = item.id.toString();

        return item;
      })

      return addressesList.concat(addressesWithValue);
    }
  },
  watch: {
    order_shipping_address_id (newVal) {
      if (newVal === "0" && !this.is_shipping_form_open) {
        this.is_shipping_form_open = true;
        this.$set(this, 'order_shipping_address_id', "");
      }
    }
  },
  data() {
    const rawCartItems = this.$store.getters.getCartItems;
    const cartItems = [];
    let subtotal = 0.0;
    const transport = 0.0;
    let currentCurrency = this.getCurrency();

    for (let i = 0; i < rawCartItems.length; i++) {
      const cartItem = [];
      cartItem.push(rawCartItems[i].quantity + 'x ' + rawCartItems[i].product.name);
      cartItem.push(this.getPriceFormatted(rawCartItems[i].unit_price, rawCartItems[i].currency));
      subtotal += rawCartItems[i].total_price;

      if ((rawCartItems[i].unit_price * rawCartItems[i].quantity).toFixed(2) !== (rawCartItems[i].total_price).toFixed(2)) {
        console.warn('does not add up');
      }

      if (rawCartItems[i].currency !== currentCurrency) {
          console.log('item currency different that global currency');
        }

      cartItems.push(cartItem)
    }

    return {
      locale: this.$i18n.locale,
      cartQty: this.$store.state.cartQty,
      rawCartItems,
      cartItems,
      sub_total: subtotal,
      transport,
      countries: [],
      loggedIn: this.$auth.loggedIn,
      company_name: this.$auth.loggedIn ? this.$auth.user.company : '',
      first_name: this.$auth.loggedIn ? this.$auth.user.firstname : '',
      last_name: this.$auth.loggedIn ? this.$auth.user.lastname : '',
      street: this.$auth.loggedIn ? this.$auth.user.street : '',
      house_number: this.$auth.loggedIn ? this.$auth.user.number : '',
      postal_code: this.$auth.loggedIn ? this.$auth.user.postal_code : '',
      city: this.$auth.loggedIn ? this.$auth.user.city : '',
      country: this.$auth.loggedIn ? this.$auth.user.country : '',
      phone: this.$auth.loggedIn ? this.$auth.user.phonenumber : '',
      email: this.$auth.loggedIn ? this.$auth.user.email : '',
      billing_as_shipping: true,
      order_shipping_address_id: "",
      shipping: {
        company_name: this.$auth.loggedIn ? this.$auth.user.company : '',
        first_name: this.$auth.loggedIn ? this.$auth.user.firstname : '',
        last_name: this.$auth.loggedIn ? this.$auth.user.lastname : '',
        street: this.$auth.loggedIn ? this.$auth.user.street : '',
        house_number: this.$auth.loggedIn ? this.$auth.user.number : '',
        postal_code: this.$auth.loggedIn ? this.$auth.user.postal_code : '',
        city: this.$auth.loggedIn ? this.$auth.user.city : '',
        country: this.$auth.loggedIn ? this.$auth.user.country : '',
      },
      tax: 0,
      grand_total: 0,
      currency: currentCurrency,
      instant_payment: false,
      remarks: '',
      order_ref: '',
      message: false,
      errorText: '',
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.cart'),
          url: 'cart'
        },
        {
          name: this.$t('components.cart.data'),
          url: 'cart-data'
        }
      ],
      is_payment_processing: false,
      is_shipping_form_open: false,
      shipping_addresses: [],
      is_order_shipping_addresses_loaded: false,
      is_order_shipping_addresses_error: false
    }
  },
  created() {
    const cartHasChanged = false;
    for (let i = 0; i < this.rawCartItems.length; i++) {
      const stockParams = {}
      stockParams.culture = this.$store.state.language
      stockParams.partid = this.rawCartItems[i].partid

      /* let [stockRow] = await Promise.all([
        parts.post(`stock_row_get`, stockParams)
      ])
      stockRow = stockRow.data.row
      if (stockRow.quantity === 0) {
        this.$store.commit('REMOVE_CART_ITEM', stockRow)
        cartHasChanged = true
      } */
    }
    if (cartHasChanged) {
      this.$forceUpdate()
    }
  },
  methods: {
    countryChanged(country) {
      this.country = country;
    },
    shippingDetailsChanged(value) {
      this.billing_as_shipping = value;

      if (!value && !this.shipping_addresses.length) {
        this.getShippingAddresses();
      }
    },
    canInstantPayment() {
      // return false;

      // TODO: adjust below when it will be used
      if(this.sub_total <= 1000){
         if(this.country === 'GB' && this.currency === 'GBP' && (this.shipping.country === 'GB' || this.billing_as_shipping === true)){
           return true;
         }
       }
       return false;
    },
    setInstantPayment(instant) {
      this.instant_payment = instant;
    },
    submitPaymentForm(order) {
      this.$store.dispatch('submitPaymentForm', {order: order});
    },
    submitOrder() {
      const body = {}
      if (this.$auth.loggedIn) {
        body.user_id = this.$auth.user.user_id;
        body.order_type = 'order';
        body.order_shipping_address_id = this.order_shipping_address_id;
      } else {
        body.company = this.company_name;
        body.firstname = this.first_name;
        body.lastname = this.last_name;
        body.street = this.street;
        body.number = this.house_number;
        body.postal_code = this.postal_code;
        body.city = this.city;
        body.country = this.country;
        body.phonenumber = this.phone;
        body.email = this.email;
        body.order_type = 'enquiry';
        body.shipping = this.shipping;
      }
      body.billing_as_shipping = this.billing_as_shipping;
      body.order_shipping_address_id = this.order_shipping_address_id;
      body.reference = this.order_ref;
      body.note = this.remarks;
      body.order_rows = [];
      body.lang = this.$i18n.locale;
      body.instant_payment = this.instant_payment;

      if (body.lang === 'ie') {
        body.lang = 'en';
      }

      for (let i = 0; i < this.rawCartItems.length; i++) {
        const row = {};
        row.product_id = this.rawCartItems[i].product.id;
        row.quantity = this.rawCartItems[i].quantity;
        row.price_per_piece = this.rawCartItems[i].unit_price;
        row.currency = this.rawCartItems[i].currency;
        body.order_rows.push(row);
      }

      this.is_payment_processing = true;
      portal
        .post('add-order', body, {
          'Content-Type': 'application/json'
        })
        .then((data) => {
          dataLayer.push({'event': 'enquiry_send_checkout'});
          this.$store.dispatch('clearCart');
          if(data.data.order_form){
            this.submitPaymentForm(data.data.order_form);
          }
          else{
            window.location.href = this.localePath('cart-thankyou');
          }
          this.is_payment_processing = false;
        })
        .catch((err) => {
          this.is_payment_processing = false;
          let errorTextNew = [];
          errorTextNew.push(this.$t('components.cart.failed'));

          if (err.response && err.response.data && err.response.data.errors) {
            let keys = Object.keys(err.response.data.errors);

            for (let i = 0; i < keys.length; i++) {
              errorTextNew.push(err.response.data.errors[keys[i]]);
            }
          }

          errorTextNew = errorTextNew.join('<br>');
          this.errorText = errorTextNew;
          this.message = true;
        })
    },
    getCurrency () {
      const priceList = this.$store.state.priceList;
      return CommonUtils.getCurrencyForPriceList(priceList);
    },
    getPriceFormatted (price, currencyCurrency = null) {
      let currency = currencyCurrency || this.currency;
      if (!price) {
        return 'P.O.A.';
      }

      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency
      }).format(price);
    },
    getCountryName (countryCode) {
      if (!countryCode) {
        return '';
      }

      let foundCountry = Countries.find(item => item.value === countryCode);
      if (!foundCountry) {
        return '';
      }

      return foundCountry.label;
    },
    getShippingAddresses() {
      if (!this.loggedIn) {
        return;
      }

      this.$set(this, 'is_order_shipping_addresses_loaded', false);
      this.$set(this, 'is_order_shipping_addresses_error', false);

      try {
        portal.get(
          'customers/get-addresses',
          {
            headers: {
              'Authorization': this.$auth.$storage._state._token_local,
            }
          }
        )
        .then((response) => {
          if (response.status !== 200 || !response.data.success || !response.data.addresses) {
            this.$set(this, 'is_order_shipping_addresses_error', true);
            throw new Error('Fetching shipping addresses failed');
          }
          this.shipping_addresses = response.data.addresses;

          if (response.data.addresses.length) {
            this.$set(this, 'order_shipping_address_id', response.data.addresses[0].id);
          }
        })
        .catch((error) => {
          console.log('error', error);
          this.$set(this, 'is_order_shipping_addresses_error', true);
        })
      } finally {
        this.$nextTick(() => {
          this.$set(this, 'is_order_shipping_addresses_loaded', true);
        })
      }
    },
    closeShippingModal (newAddress) {
      if (newAddress && newAddress.id) {
        this.shipping_addresses.push(newAddress);
        this.order_shipping_address_id = newAddress.id;
      } else {
        this.$nextTick(() => {
          this.$set(this, 'order_shipping_address_id', "");
        })
      }
      this.is_shipping_form_open = false;
    }
  }
}
</script>
